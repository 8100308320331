import { css } from '@emotion/react'
// import { useTheme } from '@findep/microfronts-core'
import { ButtonDegrade } from '@findep/mf-landings-core'
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import {
    Grid,
    Typography
} from '@material-ui/core';
import CarouselImg from '../img/CarouselImg.compilable'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
// import { AttachMoney, MoreVert } from '@material-ui/icons';
import { Oferta } from '@findep/mf-landings-core';
import formatNumber from '../../helpers/formatNumber'
import { CataloguesService } from '../../services/LandingV4'

import NameCompany from '../../helpers/NameCompany'
import { getPeriodPlural } from '../../helpers/period'
import { navigate } from '../../helpers/queryNavigate'



const catalogos = new CataloguesService()
function Gracias({ pageContext }) {
    const [datos, setDatos] = useState({
        "amount": 0,
        "estimatedPayment": 0,
        "period": "Biweekly",
        "terms": 0,
        "branch": {
            "name": "Nombre de la sucursal",
            "address": "Dirección de la sucursal",
            "neighborhood": "Colonia de la sucursal", "postalCode": "12345", "city": "Ciudad de la sucursal", "state": "Estado de la sucursal"
        },
    })
    // const [nameCompany, setNameCompany] = useState(NameCompany(pageContext.company))

    // const { palette } = useTheme()    
    const TextHeader = styled.div`
        color: primary;
        text-align: center;
        font-weight: bold;
        font-size: 2em;
    `

    const buttonCss = css`
    display: flex;
    justify-content: flex-center;
`

    const ImageContainer = styled.div`
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        margin-left: auto;
        margin-right: auto;
        height: 100%;
        width: 450;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-content: center;
    `

    async function fetchData(flujo) {
        await catalogos.getPreapproved(flujo.creditApplicationId)
            .then((res) => {
                setDatos(res.data)
                const { forceRedirect } = res.data
                if (forceRedirect !== null && forceRedirect !== undefined) {
                    navigate(forceRedirect)
                }
            })
            .catch(err => console.log("getPreapproved", err));
    }

    const saveGtmcolor = (color) => {
        let puntaje
        switch (color) {
            case 'BLUE': puntaje = '950'; break;
            case 'GREEN': puntaje = '700'; break;
            case 'YELLOW': puntaje = '400'; break;
            case 'ORANGE': puntaje = '0'; break;
            //   case 'GRAY_1':  puntaje='100'; break;
            //   case 'GRAY_2':  puntaje='0'; break;
            case 'RED': puntaje = '0'; break;
            default: puntaje = '0'; break;

        }
        return puntaje
    }

    async function colorScore(flujo) {
        catalogos.customer(pageContext.tenant, flujo.creditApplicationId)
            .then((response) => {
                const {
                    colorScore
                } = response.data

                if (colorScore !== null) {
                    const valorcolor = saveGtmcolor(colorScore)
                    window.dataLayer = window.dataLayer || [];
                    dataLayer.push({
                        'event': 'score',
                        'color': `${colorScore}`,
                        'valorColor': `${valorcolor}`
                    });
                }


            })
            .catch(error => {
                console.error(error)
            })
    }

    useEffect(() => {
        let flujo = JSON.parse(sessionStorage.getItem('flujo')) || {}
        fetchData(flujo);
        colorScore(flujo);
        sessionStorage.clear()
    }, []);

    const props = [
        { icono: ArrowForwardIcon, titulo: 'Monto:', texto: `${formatNumber(datos.amount)}` },
        { icono: ArrowForwardIcon, titulo: 'Pago:', texto: `${formatNumber(datos.estimatedPayment)}` },
        { icono: ArrowForwardIcon, titulo: 'Plazo:', texto: `${datos.terms} ${getPeriodPlural(datos.period)} ` },
        /* { icono: ArrowForwardIcon, titulo: 'Sucursal:', texto: `${datos.branch.name}`, sizeFontLi: '14px' },
        { icono: MoreVert, titulo: 'Dirección:', texto: `${datos.branch.address}`, sizeFontLi: '14px' },
        { icono: MoreVert, titulo: 'Colonia:', texto: `${datos.branch.neighborhood}`, sizeFontLi: '14px' }, */
        { icono: ArrowForwardIcon, titulo: 'Sucursal ', texto: `${datos.branch.name}  ${datos.branch.city} Col. ${datos.branch.neighborhood} ${datos.branch.address} ${datos.branch.postalCode} ${datos.branch.state}`, sizeFontLi: '14px' },
        , { icono: ArrowForwardIcon, titulo: 'Nota: ', texto: `Recuerda acudir a tu sucursal con INE y comprobante de domicilio menor a 3 meses`, sizeFontLi: '14px' }
    ]
    return (
        <div>
            <Grid container space={2} justify="center">
                <Grid item xs={12} sm={10} md={8}>
                    <Grid container justify="center">
                        <br />
                        <Typography color='primary'>
                            <TextHeader>Felicidades tienes un crédito PRE-APROBADO </TextHeader>
                            <br />
                        </Typography>
                        <Grid item xs={12} sm={6}>
                            <br />

                            <Typography css={css`padding: 1rem;`}>
                                De acuerdo con la información que nos has proporcionado “{NameCompany(pageContext.company).display}”  tiene una oferta pre-aprobada para ti.
                                <br />
                                <br />
                                <Oferta datos={props} colorOne="#FF9F01" colorTwo="#FA4616" />
                                <br />
                                <Typography>
                                    En breve recibirás una llamada por parte de la sucursal para continuar con los requisitos y concluir el trámite.  O bien, puedes acudir tú mismo . ¡Gracias!
                                </Typography>
                                <br />
                            </Typography>
                            <br />
                            <Grid container justify="center">

                                <Grid item>
                                    <div css={buttonCss} >
                                        <ButtonDegrade
                                            onClick={
                                                () => navigate(`/encuesta-salida/`)
                                            }
                                            textButton="CONTESTAR ENCUESTA"
                                        />
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>





                        <Grid item xs={12} sm={6}>
                            <ImageContainer>
                                <CarouselImg filename="1.svg" company={pageContext.company} />
                            </ImageContainer>
                        </Grid>
                    </Grid>
                </Grid>

            </Grid>
        </div>

    )
}

Gracias.propTypes = {
    image: PropTypes
}

export default Gracias